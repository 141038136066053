<template>
  <div>
    <page-title :heading="$t('accounting.lang_invoices')" :subheading="$t('accounting.lang_nav_writeBill')" :icon=icon show-previous-button></page-title>

    <div class="app-main__inner">
      <CreateInvoiceComponent/>
    </div>
  </div>
</template>

<script>
import CreateInvoiceComponent from "@/components/billing/CreateInvoiceComponent";
import PageTitle from "@/Layout/Components/PageTitle";
import shiftMixin from "../../mixins/pos/shiftMixin";
export default {
name: "CreateInvoice",
  mixins:[shiftMixin],
  components: {PageTitle, CreateInvoiceComponent},
  data: () => ({
    icon: 'pe-7s-pen icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>